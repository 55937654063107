



















import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { POST_JOB_LIMIT_ERROR } from "@/store/modules/recruiter/constants";

export default Vue.extend({
  name: "PostJobLimitError",
  data() {
    return {
      error_snackbar: true
    };
  },
  created() {
    setTimeout(this.reset_error, 6000);
  },
  computed: {
    ...mapGetters("recruiter", {
      get_post_job_limit_error: POST_JOB_LIMIT_ERROR
    })
  },
  methods: {
    ...mapMutations("recruiter", {
      set_post_job_limit_error: POST_JOB_LIMIT_ERROR
    }),
    reset_error() {
      this.error_snackbar = false;
      this.set_post_job_limit_error("");
    }
  }
});
