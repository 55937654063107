import {
  ASSESSMENT_ERROR,
  ASSESSMENT_QUESTIONS,
  ASSESSMENT_RECORD,
  CANDIDATE_INTERVIEW_LISTING,
  CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING,
  GET_CANDIDATE_LOADING,
  GET_CURRENT_QUESTION,
  GET_JOBS_LOADING,
  GET_MATCHING_JOB_BY_ID,
  GET_MATCHING_JOBS,
  GET_TOP_MATCHING_JOB,
  JOB_ID_FOR_UPSKILL,
  QUESTION_INDEX,
  SEARCHED_MATCHING_JOBS,
  TENANT_INFO,
  TENANT_INFO_DETAILS,
  TENANT_INFO_LOADING,
  TOGGLE_JOB_FAV_STATUS_LOADING,
  UPSKILL_COURSES,
  UPSKILL_COURSES_LOADING,
  CANDIDATE_INTERVIEWS,
  CANDIDATE_JOBS
} from "@/store/modules/candidates/constants";
import {
  AssessmentRecord,
  CandidateInterviews,
  CandidateInterviewsListing,
  CandidateJobs,
  CandidateState,
  MatchingJob,
  SearchedMatchingJobsPayload,
  TenantInfo
} from "@/store/modules/candidates/interfaces";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import { PrincipleAssessments } from "@/interfaces/data_objects/principle_assessments";
import { AssessmentQuestions } from "@/interfaces/responses/assessment_questions";

export default {
  [GET_CANDIDATE_LOADING]: (state: CandidateState): boolean => state.loading,
  [GET_JOBS_LOADING]: (state: CandidateState): boolean => state.jobs_loading,
  [UPSKILL_COURSES_LOADING]: (state: CandidateState): boolean =>
    state.upskill_courses_loading,
  [GET_MATCHING_JOBS]: (state: CandidateState): MatchingJob[] =>
    state.matching_jobs,
  [SEARCHED_MATCHING_JOBS]: (
    state: CandidateState
  ): SearchedMatchingJobsPayload => state.searched_matching_jobs,
  [GET_MATCHING_JOB_BY_ID]:
    (state: CandidateState) =>
    (job_id: number, search = false): MatchingJob | undefined => {
      if (search) {
        return state.candidate_jobs.top_matching_jobs.results.find(
          (_job) => _job.jid === job_id
        );
      }
      return state.candidate_jobs.matching_jobs.results.find(
        (_job) => _job.jid === job_id
      );
    },
  [GET_TOP_MATCHING_JOB]: (state: CandidateState): MatchingJob | null => {
    if (state.candidate_jobs.top_matching_jobs.results.length > 0)
      return state.candidate_jobs.top_matching_jobs.results[0];
    return null;
  },
  [JOB_ID_FOR_UPSKILL]: (state: CandidateState): number | null =>
    state.job_id_for_upskill,
  [UPSKILL_COURSES]: (
    state: CandidateState
  ): UpskillCourses.UpskillCoursesRoot | null => state.upskill_courses,
  [TOGGLE_JOB_FAV_STATUS_LOADING]: (state: CandidateState): boolean =>
    state.toggle_job_fav_status_loading,
  [CANDIDATE_INTERVIEW_LISTING]: (
    state: CandidateState
  ): CandidateInterviewsListing[] | null => state.interviews.listing,
  [CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING]: (state: CandidateState): boolean =>
    state.interviews.search_job_loading,
  [TENANT_INFO_LOADING]: (state: CandidateState): boolean =>
    state.tenant_info.loading,
  [TENANT_INFO_DETAILS]: (state: CandidateState): PrincipleAssessments | null =>
    state.tenant_info.details,
  [TENANT_INFO]: (state: CandidateState): TenantInfo => state.tenant_info,
  [ASSESSMENT_QUESTIONS]: (state: CandidateState): AssessmentQuestions.Root =>
    state.tenant_info.assessment_questions,
  [QUESTION_INDEX]: (state: CandidateState): number =>
    state.tenant_info.question_index,
  [ASSESSMENT_RECORD]: (state: CandidateState): AssessmentRecord[] =>
    state.tenant_info.assessment_record,
  [GET_CURRENT_QUESTION]: (state: CandidateState): AssessmentRecord =>
    state.tenant_info.assessment_record[state.tenant_info.question_index],
  [ASSESSMENT_ERROR]: (state: CandidateState): boolean =>
    state.tenant_info.error,
  [CANDIDATE_INTERVIEWS]: (state: CandidateState): CandidateInterviews | null =>
    state.interviews,
  // Candidate Jobs
  [CANDIDATE_JOBS]: (state: CandidateState): CandidateJobs =>
    state.candidate_jobs
};
