import { User } from "@/interfaces/data_objects/user";
import { Candidates } from "@/interfaces/data_objects/candidates";

export interface AuthState {
  loading: boolean;
  auth_error: string | null;
  user: User | null;
  company: Company | null;
  user_state: UserState;
  user_profile: Candidates.CandidateDetails | null;
  reset_password_payload: ForgotPasswordPayload | null;
  email_for_reset_password: string | null;
}

export enum UserState {
  UNAUTHENTICATED,
  EMAIL_VERIFICATION_PENDING,
  EMAIL_VERIFICATION_COMPLETED,
  ONBOARDING_PENDING,
  ONBOARDING_COMPLETED,
  RECRUITER,
  RECRUITER_ADMIN,
  CANDIDATE_PROFILE_PENDING,
  CANDIDATE,
  RESET_PASSWORD,
  RESET_PASSWORD_COMPLETED,
  ROOT_ERROR,
  TALENT_MANAGER
}

export interface Company {
  avatar_uri: string | null;
  company_description: string | null;
  company_id: number;
  company_industry: string | null;
  company_location: string | null;
  company_name: string;
  company_size: string | null;
  company_website: string | null;
}
export interface LoginActionPayload {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface SignupActionPayload {
  first_name: string;
  last_name: string | null;
  mobile_number?: string;
  email: string;
  password: string;
}

export interface SetUserPropertyPayload {
  obj_name: string;
  value: string | null | boolean | number;
}

export interface InitUserResponse extends User {
  companyDetail?: Company;
}

export interface ResetPasswordPayload {
  password: string;
  reset_password_token: string;
  email: string;
}

export interface ForgotPasswordPayload {
  reset_token?: string;
  email: string;
}

export interface UpdateCompanyPayload {
  email: string;
}

export interface RemoveAwardPayload {
  id: number;
  uid: string;
}

export interface RefreshTokenPayload {
  refresh_token: string;
}
export interface SkillFinderFunction {
  (value: string): Candidates.Skill | null;
}

export interface EmailVerfiedPayload {
  email: string;
  token: string;
}
