import {
  candidate_base_url,
  recruiter_base_url,
  user_base_url
} from "@/store/urls/base_urls";

export const GET_UNIQUE_SECTORS = user_base_url + "sectors/";
export const GET_ROLES_LIST = user_base_url + "roles/list/";

export const GET_JOB_BY_ID = user_base_url + "job-details/";

export const GET_JOB_BY_KEY = user_base_url + "job/";

export const FETCH_SKILLS_GROUP = user_base_url + "skill-groups/";
export const FETCH_SKILLS = user_base_url + "skills/";

export const FETCH_USER_WITH_PROFILE = user_base_url + "user-profile/";

export const FETCH_NOTIFICATIONS_COUNT = user_base_url + "notifications/count/";
export const FETCH_NOTIFICATIONS = user_base_url + "notifications/list/";

export const NOTIFICATIONS_ACTION = user_base_url + "notifications/seen/";

export const FETCH_USERS_INFO = candidate_base_url + "autocomplete/name?input=";

export const FETCH_ASSESSMENT_SUMMARY =
  candidate_base_url + "/principles/assessments/summary/";
export const FETCH_ASSESSMENT_PDF =
  candidate_base_url + "/principles/assessments/pdf/";

export const FETCH_ASSESSMENT =
  candidate_base_url + "/principles/assessments/user/";

export const APP_ASSESSMENTS = recruiter_base_url + "assessments/";

export const UPLOAD_FILE_CHUNK = user_base_url + "upload-file-chunk/";

export const COMPANY_SETTINGS = user_base_url + "company/settings";
