import { IApp } from "@/interfaces";
import { DigitalHumanSetting } from "@/store/modules/digital_human/interfaces";

const digital_human_setting: Record<string, DigitalHumanSetting> = {
  [IApp.Portals.HCMS]: {
    bot_avatar: "Kayla-incasualsuit-20220818",
    bot_voice_id: "2f72ee82b83d4b00af16c4771d611752"
  },
  [IApp.Portals.COREHCMS]: {
    bot_avatar: "Kayla-incasualsuit-20220818",
    bot_voice_id: "2f72ee82b83d4b00af16c4771d611752"
  },
  [IApp.Portals.COREHCS]: {
    bot_avatar: "Kayla-incasualsuit-20220818",
    bot_voice_id: "2f72ee82b83d4b00af16c4771d611752"
  }
};

export default digital_human_setting;
