import actions from "./actions";
import getters from "./getters";
import { CandidateInterview } from "./interfaces";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: (): CandidateInterview => ({
    loading: false,
    interview_id: null,
    interview_details: null,
    camera_recording_supported: false,
    camera_recording_permission: false,
    interview_history: [],
    answer_transcript: "",
    interview_stop: false
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
