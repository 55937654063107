import { IAppAssessment } from "@/interfaces";
import {
  INTERESTS_QUESTIONS_COMPLETED,
  NEXT_MOVE_ASSESSMENT_SCORES,
  NEXT_MOVE_ASSESSMENT_STATE,
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_INTERESTS_QUESTIONS,
  NEXT_MOVE_JOB_ZONE_DETAILS,
  NEXT_MOVE_QUESTIONS_PAGINATION,
  NEXT_MOVE_QUESTION_INDEX,
  NEXT_MOVE_SCORES_ASSESSMENT_ANS,
  NEXT_MOVE_SELECTED_ASSESSMENT,
  NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
  NEXT_MOVE_SELECTED_INTEREST_AREA,
  NEXT_MOVE_SELECTED_JOB_ZONE,
  RESET_MY_NEXT_MOVE_STATE
} from "./constants";
import {
  InterestAreas,
  InterestsQuestions,
  JobZoneDetails,
  MyNextMoveAssessmentStates,
  MyNextMoveScores,
  MyNextMoveState
} from "./interfaces";

export default {
  [RESET_MY_NEXT_MOVE_STATE](state: MyNextMoveState): void {
    state.state = MyNextMoveAssessmentStates.START;
    state.data_error = "";
    state.data_loading = false;
    (state.scores = {
      artistic: "0",
      conventional: "0",
      enterprising: "0",
      investigative: "0",
      realistic: "0",
      social: "0"
    }),
      (state.selected_assessment = null);
    state.selected_assessment_questions = [];
    state.scores_assessment_ans = null;
    state.interests_questions = {
      results: [],
      total: 0
    };
    state.questions_pagination = 0;
    state.question_index = 0;
    state.selected_assessment_result = null;
    state.interests_questions_completed = false;
    state.selected_job_zone = 0;
    state.selected_interest_area = InterestAreas.REALISTIC;
    state.selected_job_zone_details = JobZoneDetails.LITTLE_NO_PREPARATION;
  },
  [NEXT_MOVE_ASSESSMENT_STATE](
    state: MyNextMoveState,
    payload: MyNextMoveAssessmentStates
  ): void {
    state.state = payload;
  },
  [NEXT_MOVE_ASSESSMENT_SCORES](
    state: MyNextMoveState,
    payload: MyNextMoveScores
  ): void {
    state.scores = payload;
  },
  // Set selected assessment
  [NEXT_MOVE_SELECTED_ASSESSMENT]: (
    state: MyNextMoveState,
    payload: IAppAssessment.Assessment | null
  ): void => {
    state.selected_assessment = payload;
  },
  // Set selected assessment questions
  [NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS]: (
    state: MyNextMoveState,
    payload: IAppAssessment.AssessmentQuestionnaires[]
  ): void => {
    state.selected_assessment_questions = payload;
  },
  // Set data loading
  [NEXT_MOVE_DATA_LOADING]: (
    state: MyNextMoveState,
    payload: boolean
  ): void => {
    state.data_loading = payload;
  },
  // Set data error
  [NEXT_MOVE_DATA_ERROR]: (state: MyNextMoveState, payload: string): void => {
    state.data_error = payload;
  },
  // Set scores assessment answers
  [NEXT_MOVE_SCORES_ASSESSMENT_ANS](
    state: MyNextMoveState,
    payload: MyNextMoveScores | null
  ): void {
    state.scores_assessment_ans = payload;
  },
  // Set interests questions
  [NEXT_MOVE_INTERESTS_QUESTIONS]: (
    state: MyNextMoveState,
    payload: InterestsQuestions
  ): void => {
    state.interests_questions = payload;
  },
  // Set questions pagination
  [NEXT_MOVE_QUESTIONS_PAGINATION]: (
    state: MyNextMoveState,
    payload: number
  ): void => {
    state.questions_pagination = payload;
  },
  // Set questions pagination
  [NEXT_MOVE_QUESTION_INDEX]: (
    state: MyNextMoveState,
    payload: number
  ): void => {
    state.question_index = payload;
  },
  // Set interests questions completed
  [INTERESTS_QUESTIONS_COMPLETED]: (
    state: MyNextMoveState,
    payload: boolean
  ): void => {
    state.interests_questions_completed = payload;
  },
  // Set selected assessment result
  [NEXT_MOVE_SELECTED_ASSESSMENT_RESULT]: (
    state: MyNextMoveState,
    payload: IAppAssessment.AssessmentResults | null
  ): void => {
    state.selected_assessment_result = payload;
  },
  // Set selected job zone
  [NEXT_MOVE_SELECTED_JOB_ZONE]: (
    state: MyNextMoveState,
    payload: number
  ): void => {
    state.selected_job_zone = payload;
  },
  // Set selected interest area
  [NEXT_MOVE_SELECTED_INTEREST_AREA]: (
    state: MyNextMoveState,
    payload: InterestAreas
  ): void => {
    state.selected_interest_area = payload;
  },
  [NEXT_MOVE_JOB_ZONE_DETAILS]: (
    state: MyNextMoveState,
    payload: JobZoneDetails
  ): void => {
    state.selected_job_zone_details = payload;
  }
};
