export const RESET_CANDIDATE_INTERVIEW_STATE =
  "RESET_CANDIDATE_INTERVIEW_STATE";
export const CANDIDATE_INTERVIEW_LOADING = "CANDIDATE_INTERVIEW_LOADING";
export const CANDIDATE_INTERVIEW_ID = "CANDIDATE_INTERVIEW_ID";
export const CANDIDATE_INTERVIEW_DETAILS = "CANDIDATE_INTERVIEW_DETAILS";
export const CAMERA_RECORDING_SUPPORTED = "CAMERA_RECORDING_SUPPORTED";
export const CAMERA_RECORDING_PERMISSION = "CAMERA_RECORDING_PERMISSION";

export const CANDIDATE_INTERVIEW_HISTORY = "CANDIDATE_INTERVIEW_HISTORY";
export const CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT =
  "CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT";

export const CANDIDATE_INTERVIEW_STOP = "CANDIDATE_INTERVIEW_STOP";
