import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const onboarding_routes: RouteConfig = {
  path: "/onboarding",
  component: () => import("@/views/onboarding/OnboardingIndex.vue"),
  meta: {
    user_state: [UserState.ONBOARDING_PENDING]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "onboarding"
      }
    },
    {
      path: "",
      name: "onboarding",
      component: () => import("@/views/onboarding/OnboardingChoice.vue"),
      meta: {
        user_state: [UserState.ONBOARDING_PENDING]
      }
    },
    {
      path: "recruiter",
      name: "recruiter",
      component: () =>
        import("@/views/onboarding/recruiter/RecruiterIndex.vue"),
      meta: {
        user_state: [UserState.ONBOARDING_PENDING]
      }
    },
    {
      path: "candidate",
      component: () =>
        import("@/views/onboarding/candidate/CandidateWrapper.vue"),
      meta: {
        user_state: [UserState.ONBOARDING_PENDING]
      },
      children: [
        {
          path: "",
          name: "candidate",
          component: () =>
            import("@/views/onboarding/candidate/CandidateIndex.vue"),
          meta: {
            user_state: [UserState.ONBOARDING_PENDING]
          }
        },
        {
          path: "disclaimer",
          name: "disclaimer",
          component: () =>
            import("@/views/onboarding/candidate/CandidateDisclaimer.vue"),
          meta: {
            user_state: [UserState.ONBOARDING_PENDING]
          }
        }
      ]
    },
    {
      path: "success",
      name: "onboarding-success",
      component: () => import("@/views/onboarding/OnboardingSuccess.vue"),
      meta: {
        user_state: [UserState.ONBOARDING_COMPLETED]
      }
    }
  ]
};
