import {
  AUTH_LOADING,
  SET_CAREER_PATH,
  LOGOUT_USER,
  REMOVE_HIGHLIGHT_AWARDS,
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD_PAYLOAD,
  SET_COMPANY,
  SET_USER,
  SET_USER_PROPERTY,
  USER_PROFILE,
  USER_STATE
} from "@/store/modules/auth/constants";
import {
  AuthState,
  Company,
  ForgotPasswordPayload,
  SetUserPropertyPayload,
  UserState
} from "@/store/modules/auth/interfaces";
import { User } from "@/interfaces/data_objects/user";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { default_career_path } from "@/interfaces/responses/career_path";

export default {
  [AUTH_LOADING]: (state: AuthState, payload: boolean): void => {
    state.loading = payload;
  },
  [SET_USER]: (state: AuthState, payload: User): void => {
    state.user = payload;
  },
  [SET_USER_PROPERTY]: (
    state: AuthState,
    payload: SetUserPropertyPayload
  ): void => {
    const obj_name = payload.obj_name as keyof typeof state.user;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.user[obj_name] = payload.value;
  },
  [SET_COMPANY]: (state: AuthState, payload: Company | null): void => {
    state.company = payload;
  },
  [USER_STATE]: (state: AuthState, payload: UserState): void => {
    state.user_state = payload;
  },
  [USER_PROFILE]: (
    state: AuthState,
    payload: Candidates.CandidateDetails | null
  ): void => {
    state.user_profile = payload;
  },
  [RESET_PASSWORD_PAYLOAD]: (
    state: AuthState,
    payload: ForgotPasswordPayload | null
  ): void => {
    state.reset_password_payload = payload;
  },
  [RESET_PASS_USER_EMAIL]: (state: AuthState, payload: string | null): void => {
    state.email_for_reset_password = payload;
  },
  [LOGOUT_USER]: (state: AuthState): void => {
    state.user = null;
    state.user_state = UserState.UNAUTHENTICATED;
    state.company = null;
    state.user_profile = null;
    state.loading = false;
  },
  /**
   * For removing awards from store
   */
  [REMOVE_HIGHLIGHT_AWARDS]: (state: AuthState, award_id: number): void => {
    if (
      state.user_profile &&
      state.user_profile.profile &&
      state.user_profile.profile.media &&
      state.user_profile.profile.media.length > 0
    ) {
      state.user_profile.profile.media =
        state.user_profile.profile.media.filter(
          (media: Candidates.Media) => media.id !== award_id
        );
    }
  },
  [SET_CAREER_PATH]: (state: AuthState, payload: string[]): void => {
    if (
      state.user_profile &&
      state.user_profile.profile &&
      state.user_profile.profile !== null
    )
      if (payload) state.user_profile.profile.career_path = payload;
      else
        state.user_profile.profile.career_path =
          default_career_path.top_jobs[0].career_path;
  }
};
