import {
  NOTIFICATIONS,
  NOTIFICATIONS_LOADING,
  RESET_NOTIFICATION_STATE
} from "@/store/modules/notifications/constants";
import {
  Notifications,
  NotificationState
} from "@/store/modules/notifications/interfaces";

export default {
  [NOTIFICATIONS_LOADING]: (
    state: NotificationState,
    payload: boolean
  ): void => {
    state.loading = payload;
  },
  [NOTIFICATIONS]: (
    state: NotificationState,
    payload: Notifications | null
  ): void => {
    if (payload) state.notifications.push(payload);
    else state.notifications = [];
  },
  /**
   * Reset notification state
   */
  [RESET_NOTIFICATION_STATE]: (state: NotificationState): void => {
    state.loading = false;
    state.notifications = [];
  }
};
