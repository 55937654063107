export const RESET_MY_NEXT_MOVE_STATE = "RESET_MY_NEXT_MOVE_STATE";
export const NEXT_MOVE_ASSESSMENT_STATE = "NEXT_MOVE_ASSESSMENT_STATE";
export const NEXT_MOVE_ASSESSMENT_SCORES = "NEXT_MOVE_ASSESSMENT_SCORES";
export const NEXT_MOVE_SELECTED_ASSESSMENT = "NEXT_MOVE_SELECTED_ASSESSMENT";
export const NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS =
  "NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS";
export const NEXT_MOVE_DATA_ERROR = "NEXT_MOVE_DATA_ERROR";
export const NEXT_MOVE_DATA_LOADING = "NEXT_MOVE_DATA_LOADING";
export const NEXT_MOVE_SCORES_ASSESSMENT_ANS =
  "NEXT_MOVE_SCORES_ASSESSMENT_ANS";
export const NEXT_MOVE_INTERESTS_QUESTIONS = "NEXT_MOVE_INTERESTS_QUESTIONS";
export const NEXT_MOVE_QUESTIONS_PAGINATION = "NEXT_MOVE_QUESTIONS_PAGINATION";
export const NEXT_MOVE_QUESTION_INDEX = "NEXT_MOVE_QUESTION_INDEX";

export const INTERESTS_QUESTIONS_COMPLETED = "INTERESTS_QUESTIONS_COMPLETED";

export const NEXT_MOVE_SELECTED_ASSESSMENT_RESULT =
  "NEXT_MOVE_SELECTED_ASSESSMENT_RESULT";

export const NEXT_MOVE_SELECTED_JOB_ZONE = "NEXT_MOVE_SELECTED_JOB_ZONE";
export const NEXT_MOVE_SELECTED_INTEREST_AREA =
  "NEXT_MOVE_SELECTED_INTEREST_AREA";

export const NEXT_MOVE_JOB_ZONE_DETAILS = "NEXT_MOVE_JOB_ZONE_DETAILS";
