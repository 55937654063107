import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { LanguageKey } from "@/store/modules/common/interfaces";

Vue.use(VueI18n);

/**
 * load locales messages from the json files
 */
function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
/**
 * Return the locale string of user selected language from the local storage of the browser
 */
function getLocal(): string | null {
  let locale;
  const local_local = localStorage.getItem("locale");
  if (
    local_local === LanguageKey.ENGLISH ||
    local_local === LanguageKey.ARABIC
  ) {
    locale = local_local;
  } else {
    localStorage.setItem("locale", LanguageKey.ENGLISH);
    locale = localStorage.getItem("locale");
  }
  return locale;
}

export default new VueI18n({
  locale: getLocal() || LanguageKey.ENGLISH,
  fallbackLocale: LanguageKey.ENGLISH,
  messages: loadLocaleMessages()
});
