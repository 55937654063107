import { Commit } from "vuex";
import {
  MARK_NOTIFICATIONS_AS_READ,
  NOTIFICATIONS
} from "@/store/modules/notifications/constants";

export default {
  [MARK_NOTIFICATIONS_AS_READ]: ({ commit }: { commit: Commit }): void => {
    commit(NOTIFICATIONS, null);
  }
};
