import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const candidate_interview_routes: RouteConfig = {
  path: "interviews",
  component: () =>
    import("@/views/candidate/interviews/CandidateInterviewsIndex.vue"),
  meta: {
    user_state: [UserState.CANDIDATE]
  },
  children: [
    {
      path: "",
      name: "candidates-interviews",
      component: () =>
        import("@/views/candidate/interviews/CandidateInterviewsListing.vue"),
      meta: {
        name: "nav-sidebar.interviews",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "start/:interview_id",
      name: "interview-start",
      component: () =>
        import("@/views/candidate/interviews/CandidateInterviewRoot.vue"),
      meta: {
        name: "nav-sidebar.interviews",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.CANDIDATE]
      }
    }
  ]
};
