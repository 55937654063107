/* eslint-disable @typescript-eslint/no-explicit-any */
class ErrorService {
  errorAPICallCount = 0;
  constructor() {
    this.initHandler();
  }
  /**
   * function for logging the error
   * */
  onError(error: any, errorType: string) {
    // Send Error to Log
    if (error) {
      this.errorLogEngine(error, errorType);
    } else {
      //Do nothing
    }
  }
  /**
   * function for logging any run-time front end related error
   * */
  initHandler() {
    window.onerror = (message, url, lineNo, columnNo, error) => {
      if (error) {
        this.onError(message, "Window");
      } else {
        //Do nothing
      }
    };
  }
  /**
   * function for call an api to log the error
   * */
  async errorLogEngine(error: any, errorType: string) {
    if (process.env.NODE_ENV !== "production") {
      console.log("error", error);
      console.log("errorType", errorType);
    }
  }
}

export default new ErrorService();
