import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const recruiter_assessment_routes: RouteConfig = {
  path: "assessments",
  component: () =>
    import("@/views/recruiter/assessment/RecruiterAssessmentIndex.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "assessment-listing",
      component: () =>
        import("@/views/recruiter/assessment/RecruiterAssessmentListing.vue"),
      meta: {
        name: "nav-sidebar.assessment",
        icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "report/:assessment_id",
      name: "assessment-report-recruiter",
      component: () => import("@/views/shared/AssessmentReport.vue"),
      meta: {
        name: "nav-sidebar.assessment",
        icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    }
  ]
};
