import getters from "@/store/modules/my_next_move/getters";
import mutations from "@/store/modules/my_next_move/mutations";
import actions from "@/store/modules/my_next_move/actions";
import {
  InterestAreas,
  JobZoneDetails,
  MyNextMoveAssessmentStates,
  MyNextMoveState
} from "./interfaces";
export default {
  namespaced: true,
  state: (): MyNextMoveState => ({
    data_error: "",
    data_loading: false,
    state: MyNextMoveAssessmentStates.START,
    scores: {
      artistic: "0",
      conventional: "0",
      enterprising: "0",
      investigative: "0",
      realistic: "0",
      social: "0"
    },
    selected_assessment: null,
    selected_assessment_questions: [],
    scores_assessment_ans: null,
    interests_questions: {
      results: [],
      total: 0
    },
    questions_pagination: 0,
    question_index: 0,
    selected_assessment_result: null,
    interests_questions_completed: false,
    selected_job_zone: 0,
    selected_interest_area: InterestAreas.REALISTIC,
    selected_job_zone_details: JobZoneDetails.LITTLE_NO_PREPARATION
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
