export const DIGITAL_HUMAN_LOADING = "DIGITAL_HUMAN_LOADING";

export const BOT_BACKGROUND = "BOT_BACKGROUND";

export const HYGEN_SESSION_ID = "HYGEN_SESSION_ID";
export const HYGEN_SESSION_INFO = "HYGEN_SESSION_INFO";
export const HYGEN_RENDER_ID = "HYGEN_RENDER_ID";
export const HYGEN_PEER_CONNECTION = "HYGEN_PEER_CONNECTION";

export const INIT_DIGITAL_HUMAN = "INIT_DIGITAL_HUMAN";

export const DIGITAL_HUMAN_SETTING = "DIGITAL_HUMAN_SETTING";

export const INIT_HYGEN_SESSION = "INIT_HYGEN_SESSION";
export const INIT_HYGEN_SESSION_ERROR = "INIT_HYGEN_SESSION_ERROR";

export const START_HYGEN_SESSION = "START_HYGEN_SESSION";

export const SPEAK_HYGEN_BOT = "SPEAK_HYGEN_BOT";

export const HANDLE_HYGEN_ICE = "HANDLE_HYGEN_ICE";

export const STOP_HYGEN_SESSION = "STOP_HYGEN_SESSION";
export const SESSION_INITIALIZED_SUCCESSFULLY =
  "SESSION_INITIALIZED_SUCCESSFULLY";

export const RESET_DIGITAL_HUMAN = "RESET_DIGITAL_HUMAN";
