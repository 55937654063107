import { Interview } from "@/interfaces/responses/interviews/interviews";
import {
  CAMERA_RECORDING_PERMISSION,
  CAMERA_RECORDING_SUPPORTED,
  CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT,
  CANDIDATE_INTERVIEW_DETAILS,
  CANDIDATE_INTERVIEW_HISTORY,
  CANDIDATE_INTERVIEW_ID,
  CANDIDATE_INTERVIEW_LOADING,
  CANDIDATE_INTERVIEW_STOP,
  RESET_CANDIDATE_INTERVIEW_STATE
} from "./constants";
import { CandidateInterview } from "./interfaces";
import { InterviewChat } from "@/interfaces/candidate/candidate_interview";

export default {
  [RESET_CANDIDATE_INTERVIEW_STATE]: (state: CandidateInterview): void => {
    state.loading = false;
    state.interview_id = null;
    state.interview_details = null;
    state.camera_recording_supported = false;
    state.camera_recording_permission = false;
    state.interview_history = [];
    state.answer_transcript = "";
  },
  [CANDIDATE_INTERVIEW_LOADING]: (
    state: CandidateInterview,
    loading: boolean
  ): void => {
    state.loading = loading;
  },
  [CANDIDATE_INTERVIEW_ID]: (
    state: CandidateInterview,
    interview_id: number
  ): void => {
    state.interview_id = interview_id;
  },
  [CANDIDATE_INTERVIEW_DETAILS]: (
    state: CandidateInterview,
    interview_details: Interview.Response | null
  ): void => {
    state.interview_details = interview_details;
  },
  [CAMERA_RECORDING_SUPPORTED]: (
    state: CandidateInterview,
    camera_recording_supported: boolean
  ): void => {
    state.camera_recording_supported = camera_recording_supported;
  },
  [CAMERA_RECORDING_PERMISSION]: (
    state: CandidateInterview,
    camera_recording_permission: boolean
  ): void => {
    state.camera_recording_permission = camera_recording_permission;
  },
  [CANDIDATE_INTERVIEW_HISTORY]: (
    state: CandidateInterview,
    payload: InterviewChat[]
  ): void => {
    if (payload.length > 0)
      state.interview_history = state.interview_history.concat(payload);
    else state.interview_history = [];
  },
  [CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT]: (
    state: CandidateInterview,
    answer_transcript: string
  ): void => {
    state.answer_transcript = answer_transcript;
  },
  [CANDIDATE_INTERVIEW_STOP]: (
    state: CandidateInterview,
    interview_stop: boolean
  ): void => {
    state.interview_stop = interview_stop;
  }
};
