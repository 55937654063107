import { IOnetResults } from "./responses";

export enum List {
  MY_NEXT_MOVE_ASSESSMENT = "my_next_move_assessment",
  MY_NEXT_MOVE_SCORES_ASSESSMENT = "my_next_move_scores_assessment",
  ENGLISH_SPEAKING_V1 = "english_v1",
  ENGLISH_SPEAKING_V2 = "english_v2",
  ANNOTATION_V1 = "annotation_v1",
  ANNOTATION_V2 = "annotation_v2",
  CHAT_BOT_MESSAGES = "chat_bot_messages"
}
export enum Result {
  RESULT_PROCESSING = "RESULT_PROCESSING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  IN_PROGRESS = "IN_PROGRESS"
}
export interface Assessment {
  id: number;
  assessment_name: string;
  assessment_type: string;
  created_at: string;
  allowed_time: number | number;
}
export interface AssessmentQuestionnaires {
  id: number;
  assessment_id: number;
  question_type: QuestionType;
  question_text: Text;
  created_by: number | null;
  created_at: string;
  updated_at: string | null;
  is_question_enable: boolean;
  question_time_allowed: number | null; // Seconds
  question_options: QuestionOptions | null;
}

export interface AssessmentAnswers {
  id: number;
  user_id: number;
  question_id: number;
  answer_type: AnswerType;
  answer: string;
  answer_url?: JSON;
  created_at?: Date;
  updated_at?: Date;
}
export interface AssessmentResults {
  id: number;
  assessment_id: number;
  user_id: number;
  created_at: string;
  updated_at: string | null;
  total_questions: number | null;
  current_question: number | null;
  status: Result | null;
  result: JSON | null | IOnetResults.Root;
  urls: ResultUrls | null;
}
export interface ResultUrls {
  screen_recording?: string;
  screen_shots?: string[];
  camera_recording?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report?: any;
}
export interface QuestionOptions {
  question_voice_url?: string;
  data_table?: {
    columns: string[];
    rows: DataTableRows[];
  };
  question_material?: string;
  allowed_characters: number[];
  ref?: AnnotationAssessmentRefs;
  title?: string;
  responses?: QuestionResponses[];
  filter?: QuestionFilter;
  table?: {
    columns: string[];
    rows: {
      title: string;
      col1: string;
      col2: string;
      col3: string;
    };
  };
}

export enum QuestionFilter {
  SPEAKING = "speaking",
  WRITTEN = "written"
}
export interface DataTableRows {
  title: string;
  values: string[];
}
export enum QuestionType {
  TEXT = "text",
  JSON = "json",
  LONG_TEXT = "long_text",
  HTML = "html",
  SCORES = "scores"
}
export enum AnswerType {
  TEXT = "text",
  JSON = "json",
  LONG_TEXT = "long_text",
  HTML = "html",
  SCORES = "scores"
}
export enum AnnotationAssessmentRefs {
  PART1 = "part1",
  PART2 = "part2",
  PART3 = "part3",
  PART4 = "part4",
  PART5 = "part5"
}

export interface QuestionResponses {
  content?: string;
  options?: QuestionResponsesOptions[];
  arabic?: string;
  title?: string;
  ans?: string;
}
export interface QuestionResponsesOptions {
  option1?: string;
  option2?: string;
  ans?: string;
}
