import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueCalendly from "vue-calendly";
import { errorInterceptor, requestInterceptor } from "./utils/interceptor";
import "@/assets/styles/global.scss";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as GmapVue from "gmap-vue";
import InlineSvg from "vue-inline-svg";
import Vuelidate from "vuelidate";
import VueQuillEditor from "vue-quill-editor";

Vue.config.productionTip = false;

// require styles for vue-quill-editor
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

//Configure vue error handler to send errors for log
// Vue.config.errorHandler = function (err, vm, info) {
//   ErrorService.onError(err.toString(), "VUE");
// };

Vue.use(LottieVuePlayer);
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: "places",
    v: "3.51",
    dynamicLoad: true
  }
});
Vue.component("inline-svg", InlineSvg);
Vue.use(VueQuillEditor);
Vue.use(Vuelidate);
Vue.use(VueCalendly);
// Filter to capitalize every first work of strings
Vue.filter("capitalize", function (text: string) {
  if (text.length === 2) return text.toUpperCase();
  return text.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
});

/**
 * initiate the axios request interceptor
 * */
requestInterceptor();
/**
 * initialize the error interceptor
 * */
errorInterceptor();

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
