import {
  HandleHygenIcePayload,
  InitHygenSessionPayload,
  SpeakHygenBotPayload,
  StartHygenSessionPayload,
  StopHygenSessionPayload
} from "@/store/modules/digital_human/interfaces";
import axios, { AxiosResponse } from "axios";
import {
  HANDLE_HYGEN_ICE,
  INIT_HYGEN_SESSION,
  SPEAK_HYGEN_BOT,
  START_HYGEN_SESSION,
  STOP_HYGEN_SESSION
} from "@/store/urls/hygen";

export const init_hygen_session = async (
  payload: InitHygenSessionPayload
): Promise<AxiosResponse> => {
  return await axios.post(INIT_HYGEN_SESSION, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": process.env.VUE_APP_HYGEN_API
    }
  });
};

export const start_hygen_session = async (
  payload: StartHygenSessionPayload
): Promise<AxiosResponse> => {
  return await axios.post(START_HYGEN_SESSION, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": process.env.VUE_APP_HYGEN_API
    }
  });
};
export const stop_hygen_session = async (
  payload: StopHygenSessionPayload
): Promise<AxiosResponse> => {
  return await axios.post(STOP_HYGEN_SESSION, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": process.env.VUE_APP_HYGEN_API
    }
  });
};

export const speak_hygen_bot = async (
  payload: SpeakHygenBotPayload
): Promise<AxiosResponse> => {
  return await axios.post(SPEAK_HYGEN_BOT, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": process.env.VUE_APP_HYGEN_API
    }
  });
};

export const handle_hygen_ice = async (
  payload: HandleHygenIcePayload
): Promise<AxiosResponse> => {
  return await axios.post(HANDLE_HYGEN_ICE, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": process.env.VUE_APP_HYGEN_API
    }
  });
};
