export namespace AppRoutes {
  export const Auth = {
    ROOT: "/auth",
    SIGNIN: "/auth/signin",
    SIGNUP: "/auth/signup"
  } as const;

  export const Candidae = {
    ROOT: "/candidate",
    DASHBOARD: "/candidate/dashboard/",
    JOBS: "/candidate/jobs/",
    JOBS_VIEW: "/candidate/jobs/view/",
    JOBS_UPSKILL: "/candidate/jobs/upskill-career/",
    INTERVIEWS: "/candidate/interviews/",
    INTERVIEW_PROGRESS: "/candidate/interviews/start/",
    SETTINGS: "/settings/account/",
    PROFILE: "/candidate/profile/",
    ASSESSMENTS: "/candidate/assessments/",
    PERSONALITY_ASSESSMENT: "/candidate/assessments/personality/",
    PERSONALITY_ASSESSMENT_SUCCESS:
      "/candidate/assessments/personality/success/",
    PERSONALITY_ASSESSMENT_REPORT: "/candidate/assessments/personality/report/",
    MY_NEXT_MOVE_ASSESSMENT: "/candidate/assessments/my-next-move/"
  } as const;
}
