import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";
// import { recruiter_assessment_routes } from "@/router/sub_routes/recruiter/recruiter_assessment_routes";
// import { recruiter_candidates } from "@/router/sub_routes/recruiter/candidates";
// import { recruiter_interview_routes } from "@/router/sub_routes/recruiter/interview_routes";
import { talent_manager_jobs } from "@/router/sub_routes/talent_manager/jobs";

export const talent_manager_routes: RouteConfig = {
  path: "/talent-manager",
  component: () => import("@/views/talent_manager/TalentManagerWrapper.vue"),
  meta: {
    user_state: [UserState.TALENT_MANAGER]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "talent-manager-dashboard"
      },
      meta: {
        name: "nav-sidebar.talent-manager",
        icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
        user_state: [UserState.TALENT_MANAGER]
      }
    },
    {
      path: "dashboard",
      name: "talent-manager-dashboard",
      component: () =>
        import("@/views/talent_manager/TalentManagerDashboard.vue"),
      meta: {
        name: "nav-sidebar.talent-manager",
        icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
        user_state: [UserState.TALENT_MANAGER]
      }
    },

    // todo for these three routes
    // TODO => Add Talent Manager State || Change Sub routes for Talent Manager after the confirmation of Faiz Bhai
    // recruiter_assessment_routes,
    // recruiter_candidates,
    // recruiter_interview_routes,
    talent_manager_jobs // State is added in this route of talent manager
  ]
};
